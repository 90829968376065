import api from "!../node_modules/_style-loader@2.0.0@style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/_css-loader@3.6.0@css-loader/dist/cjs.js!../node_modules/_vue-loader@15.10.1@vue-loader/lib/loaders/stylePostLoader.js!../node_modules/_postcss-loader@5.3.0@postcss-loader/dist/cjs.js!../node_modules/_sass-loader@11.1.1@sass-loader/dist/cjs.js!../node_modules/_style-resources-loader@1.5.0@style-resources-loader/lib/index.js??clonedRuleSet-1.use[4]!../node_modules/_vue-loader@15.10.1@vue-loader/lib/index.js??vue-loader-options!./App.vue?vue&type=style&index=0&id=4c425687&prod&lang=scss&";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};