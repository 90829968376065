/*
 * @Description:
 * @Date: 2021-09-06 10:56:44
 */
const Layout = () => import('@/layout/index.vue')
const prefix = '/gov'
const prefixPath = 'views' + prefix
export default [
  {
    path: prefix,
    name: 'Gov',
    component: Layout,
    redirect: prefix + '/index',
    meta: {
      auth: ['ADMIN']
    },
    children: [
      {
        path: 'index',
        name: 'GovIndex',
        meta: {
          title: '首页',
          icon: 'icon-shouye',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'GovIndex' */ '@/' + prefixPath + '/home')
      }
    ]
  },
  {
    path: prefix + '/enterprise',
    name: 'Enterprise',
    meta: {
      title: '企业碳账户管理',
      icon: 'icon-qiye',
      auth: ['ADMIN']
    },
    redirect: prefix + '/enterprise/carbon',
    component: Layout,
    children: [
      {
        path: 'carbon',
        name: 'Carbon',
        meta: {
          title: '碳账户管理',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'Carbon' */ '@/' + prefixPath + '/enterprise/carbon')
      },
      {
        path: 'energy',
        name: 'Energy',
        meta: {
          title: '能耗数据管理',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'Energy' */ '@/' + prefixPath + '/enterprise/energy')
      },
      {
        path: 'list',
        name: 'EntList',
        meta: {
          title: '企业信息管理',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'EntList' */ '@/' + prefixPath + '/enterprise/list')
      }
    ]
  },
  {
    path: prefix + '/target',
    name: 'Target',
    meta: {
      title: '企业碳账户预警',
      icon: 'icon-wuyeguanli',
      auth: ['ADMIN']
    },
    component: Layout,
    redirect: prefix + '/target/warning',
    children: [
      {
        path: 'list',
        name: 'TargetList',
        hidden: true,
        meta: {
          title: '企业碳账户预警',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'TargetList' */ '@/' + prefixPath + '/target')
      },
      {
        path: 'warning',
        name: 'Warning',
        meta: {
          title: '预警管理',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'Warning' */ '@/' + prefixPath + '/enterprise/warning')
      }
    ]
  },
  {
    path: prefix + '/directReport',
    name: 'DirectReport',
    component: Layout,
    meta: {
      auth: ['ADMIN'],
      title: '数据直报',
      icon: 'el-icon-s-data'
    },
    children: [
      {
        path: 'energy',
        name: 'Energy',
        meta: {
          title: '能源消费数据',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/directReport/energy')
      },
      {
        path: 'economy',
        name: 'Economy',
        meta: {
          title: '经济数据',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/directReport/economy')
      },
      {
        path: 'newEnergy',
        name: 'NewEnergy',
        meta: {
          title: '新能源数据',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/directReport/newEnergy')
      }
    ]
  },
  {
    path: prefix + '/special',
    name: 'Special',
    meta: {
      auth: ['ADMIN']
    },
    component: Layout,
    redirect: prefix + '/special/index',
    children: [
      {
        path: 'index',
        name: 'SpecialIndex',
        meta: {
          title: '专题分析',
          icon: 'icon-zhuantifenxi',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'SpecialIndex' */ '@/' + prefixPath + '/special/index')
      }
    ]
  },
  {
    path: prefix + '/message',
    name: 'Message',
    component: Layout,
    meta: {
      auth: ['ADMIN']
    },
    redirect: prefix + 'message/list',
    children: [
      {
        path: 'list',
        name: 'MessageList',
        meta: {
          title: '信息通知',
          icon: 'icon-xiaoxi',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'MessageList' */ '@/' + prefixPath + '/message')
      }
    ]
  },
  {
    path: prefix + '/config',
    name: 'Config',
    component: Layout,
    meta: {
      auth: ['ADMIN']
    },
    redirect: prefix + 'config/list',
    children: [
      {
        path: 'list',
        name: 'ConfigList',
        meta: {
          title: '碳达峰配置',
          icon: 'icon-shouye',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'MessageList' */ '@/' + prefixPath + '/config')
      }
    ]
  },
  {
    path: '/visualization',
    name: 'Visualization',
    hidden: true,
    component: () => import(/* webpackChunkName: 'Visualization' */ '@/' + prefixPath + '/visualization'),
    meta: {
      auth: ['ADMIN']
    },
    redirect: '/visualization',
    children: [
      {
        path: '',
        meta: {
          title: '综合态势',
          icon: 'el-icon-s-marketing',
          auth: ['ADMIN']
        }
      }
    ]
  },
  {
    path: prefix + '/company',
    name: 'Company',
    hidden: true,
    meta: {
      title: '企业详情',
      auth: ['ADMIN']
    },
    component: () => import('@/views/ent/home')
  },
  {
    path: '/reduction',
    name: 'Reduction',
    hidden: true,
    component: () => import(/* webpackChunkName: 'Visualization' */ '@/' + prefixPath + '/reduction'),
    meta: {
      auth: ['ADMIN']
    },
    redirect: '/reduction',
    children: [
      {
        path: '',
        meta: {
          title: '减污降碳',
          icon: 'el-icon-s-marketing',
          auth: ['ADMIN']
        }
      }
    ]
  },
  {
    path: '/quota',
    name: 'CarbonQuota',
    hidden: true,
    component: () => import(/* webpackChunkName: 'Visualization' */ '@/' + prefixPath + '/quota'),
    meta: {
      auth: ['ADMIN']
    },
    redirect: '/quota',
    children: [
      {
        path: '',
        meta: {
          title: '碳排放监测',
          icon: 'el-icon-s-marketing',
          auth: ['ADMIN']
        }
      }
    ]
  }
]
