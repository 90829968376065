/*
 * @Description:
 * @Date: 2021-03-24 10:47:22
 * @FilePath: \da-tool\src\config\index.js
 */
export default {
  imgDomain: 'https://shuxi-ct.oss-cn-hangzhou.aliyuncs.com/',
  AMapKey: 'd2abc2fa5f907f1900d5ceff8524a4f6',
  crypto: {
    iv: 'Kb3Xp4XPhyYUgRi3',
    key: 'IB5ZXAcNbofJCgLB'
  },
  httpPrefix: 'shuxi-xc-cb-ent'
}
