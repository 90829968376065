/*
 * @Description:
 * @Date: 2023-02-28 14:17:16
 * @FilePath: \xinchang-carbon\src\router\ent.js
 */
/*
 * @Description:
 * @Date: 2021-09-06 10:56:22
 */
const Layout = () => import('@/layout/index.vue')
const prefix = '/ent'
const prefixPath = 'views' + prefix
export default [
  {
    path: prefix,
    name: 'Ent',
    component: Layout,
    meta: {
      auth: ['ent']
    },
    redirect: prefix + '/index',
    children: [
      {
        path: 'index',
        name: 'EntIndex',
        meta: {
          title: '首页',
          icon: 'el-icon-s-home',
          auth: ['ent']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/home')
      }
    ]
  },
  {
    path: prefix + '/directReport',
    name: 'DirectReport',
    component: Layout,
    meta: {
      auth: ['ADMIN'],
      title: '数据直报',
      icon: 'el-icon-s-data'
    },
    children: [
      {
        path: 'energy',
        name: 'Energy',
        meta: {
          title: '能源消费数据',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/directReport/energy')
      },
      {
        path: 'economy',
        name: 'Economy',
        meta: {
          title: '经济数据',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/directReport/economy')
      },
      {
        path: 'newEnergy',
        name: 'NewEnergy',
        meta: {
          title: '新能源数据',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/' + prefixPath + '/directReport/newEnergy')
      }
    ]
  },
  {
    path: prefix + '/message',
    name: 'Message',
    component: Layout,
    meta: {
      auth: ['ADMIN']
    },
    redirect: prefix + 'message/list',
    children: [
      {
        path: 'list',
        name: 'MessageList',
        meta: {
          title: '信息通知',
          icon: 'icon-xiaoxi',
          auth: ['ADMIN']
        },
        component: () => import(/* webpackChunkName: 'MessageList' */ '@/' + prefixPath + '/message')
      }
    ]
  }
]
