/*
 * @Description:element
 * @Date: 2021-03-03 18:27:43
 */
import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
// import 'element-ui/lib/theme-chalk/base.css'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import {
  Button,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Message,
  MessageBox,
  Progress,
  Main,
  Scrollbar,
  Menu,
  MenuItemGroup,
  Header,
  Submenu,
  Switch,
  MenuItem,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Divider,
  Table,
  TableColumn,
  Aside,
  Pagination,
  Checkbox,
  CheckboxGroup,
  Card,
  Link,
  Dialog,
  Select,
  Option,
  Upload,
  Loading,
  Tabs,
  TabPane,
  Cascader,
  DatePicker,
  Radio,
  RadioGroup,
  Tag,
  RadioButton,
  Steps,
  Notification,
  Step,
  Empty,
  Descriptions,
  DescriptionsItem,
  ButtonGroup,
  Drawer,
  Icon,
  Alert,
  Popconfirm,
  InputNumber
} from 'element-ui'

Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(Tag)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Scrollbar)
// Vue.component(Message.name, Message)
// Vue.component(MessageBox.name, MessageBox)
Vue.use(Menu)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Tooltip)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Container)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Header)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Card)
Vue.use(Link)
Vue.use(Divider)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Loading)
Vue.use(Progress)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Cascader)
Vue.use(DatePicker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Empty)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(ButtonGroup)
Vue.use(Drawer)
Vue.use(Icon)
Vue.use(Alert)
Vue.use(Popconfirm)
Vue.component(CollapseTransition.name, CollapseTransition)

Vue.prototype.$message = Message
// Vue.prototype.$confirm = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification
Vue.prototype.$alert = MessageBox.alert
