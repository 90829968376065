/*
 * @Description:
 * @Date: 2021-03-24 11:25:36
 */
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import '@/style/index.scss' // glob scss
import store from './store'
import './plugins/index'
import tools from '@/utils/tools'
import '@/utils/rem'
import config from './config/index'
Vue.use(tools)
Vue.config.productionTip = false
Vue.prototype.$config = config
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
