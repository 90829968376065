<!--
 * @Description: 
 * @Date: 2022-02-24 14:24:25
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    /* var t = window.devicePixelRatio // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    if (t !== 1) {
      // 如果进行了缩放，也就是不是1
      document.body.style.zoom = -0.5 * t + 1.55 // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
    } */
  }
}
</script>
<style lang="scss">
@import url('//at.alicdn.com/t/font_2756630_funo0qagkqj.css');
#app {
  width: 100%;
  height: 100%;
}
</style>
