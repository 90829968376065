/*
 * @Description:
 * @Date: 2021-03-13 20:41:49
 * @FilePath: /xinchang-carbon/src/router/index.js
 */
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'
import Routers from './common' // 引入通用模块
import govRouter from './gov'
import entRouter from './ent'
import config from '../config'
import CryptoJS from 'crypto-js'
import { govLogin } from '../api/index'
import { getToken, setToken } from '../utils/index'

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const creatRouter = () => {
  return new Router({
    mode: 'history',
    routes: Routers,
    scrollBehavior() {
      // 清空历史记录设定最新URL记录 防止滚动手势模拟点击浏览器返回
      // history.pushState(null, null, document.URL)
      return { x: 0, y: 0 }
    }
  })
}

const router = creatRouter()
// 解决addRoute不能删除动态路由问题
export function resetRouter() {
  const reset = creatRouter()
  router.matcher = reset.matcher
}
// 当前路由是否在路由内
function isPageInRoute(routes, to, path = '') {
  return routes.some(item => {
    if (item.children && item.children.length > 1) {
      return isPageInRoute(item.children, to, path + (item.path.indexOf('/') > -1 ? '' : '/') + item.path)
    }
    let onlyChildInRoute = false
    if (item.children && item.children.length === 1) {
      onlyChildInRoute = item.path + '/' + item.children[0].path === to.path
    }
    return item.path === to.path || item.redirect === to.path || onlyChildInRoute || path + '/' + item.path === to.path
  })
}

// 导航守卫
router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.title) document.title = to.meta.title // 动态修改标题
  NProgress.start()
  const token = getToken()
  if (token) {
    if (!store.getters.userInfo.userType) {
      // 获取用户信息
      await store.dispatch('app/userInfo')
    }
    // 当前登录的是企业端还是政府端
    const isAdmin = store.getters.userInfo.userType === 'ADMIN'
    if (to.path === '/') {
      next({ path: isAdmin ? '/gov/index' : '/ent/index' })
    } else if (!isAdmin && to.name === 'Login') {
      next({ path: '/ent/index' })
    } else if (to.meta.title || to.name === 'Login') {
      next()
    } else {
      // 获取字典项
      await store.dispatch('app/dictsAll')
      // 动态加载路由
      const accessRoutes = await store.dispatch('app/authRouter', isAdmin ? govRouter : entRouter)
      accessRoutes.map(item => {
        router.addRoute(item)
      })
      router.options.routes = [...Routers, ...accessRoutes]
      if (!isPageInRoute(router.options.routes, to)) {
        next({ replace: true, path: '/404' })
      } else {
        next({ ...to, replace: true })
      }
    }
  } else {
    if (to.name === 'Login') {
      next()
    } else if (to.fullPath.indexOf('/visualization?token=6931EF0DB73B667986C3F8ED5E6ECCBD') > -1) {
      let data = {
        userPhone: '18756309101',
        password: 'tmlt123456',
        entId: ''
      }
      const iv = CryptoJS.enc.Utf8.parse(config.crypto.iv)
      const key = CryptoJS.enc.Utf8.parse(config.crypto.key)
      data.timestamp = new Date().getTime()
      data = CryptoJS.enc.Utf8.parse(JSON.stringify(data))
      const enc = CryptoJS.AES.encrypt(data, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString()
      govLogin({ enc }).then(res => {
        setToken(res.data.sessionId)
        next({ ...to, replace: true })
      })
    } else {
      next({ name: 'Login' })
    }
  }
})
router.afterEach((to, from) => {
  NProgress.done()
})
export default router
