/*
 * @Description:
 * @Date: 2021-09-06 09:49:27
 */
import Axios from 'axios'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import { getToken, removeToken } from '../utils/index'

const axios = Axios.create({
  // 基础url前缀
  // baseURL: '/test-api',
  baseURL: '/api',
  timeout: 50000
})

axios.interceptors.request.use(
  config => {
    // 请求拦截器
    NProgress.start()
    if (getToken()) config.headers.sessionId = getToken()
    return config
  },
  error => {
    NProgress.done()
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  // 响应拦截器
  response => {
    setTimeout(() => {
      NProgress.done()
    }, 0)
    const data = response.data
    if (data.code === -1) {
      // 返回接口返回的错误信息
      Message.error(`${data.msg}`)
      return Promise.reject(data.msg)
    }
    return data
  },
  error => {
    NProgress.done()
    if (error.response) {
      if (error.response.status === 401) {
        removeToken()
        location.reload()
      } else {
        Message.error(`${error.response.status} 服务器异常 ${error.request.responseURL}`)
      }
    }
    return Promise.reject(error.response) // 返回接口返回的错误信息
  }
)
export default axios
