/*
 * @Description:
 * @Date: 2021-06-24 18:53:39
 * @FilePath: /xinchang-carbon/src/router/common.js
 */

export default [
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/404',
    name: 'error404',
    hidden: true,
    meta: { title: '未找到' },
    component: () => import('@/views/error/404.vue')
  }
]
