<!--
 * @Description:
 * @Date: 2022-01-11 16:10:52
 * @FilePath: \xinchang-carbon\src\components\global\Drawer.vue
-->
<template>
  <div class="drawer">
    <div :class="maskClass" @click="closeMask"></div>
    <div :class="mainClass" :style="mainStyle" class="main" v-loading="loading" element-loading-text="提交中">
      <div class="drawer-head">
        <el-divider content-position="left">
          <p class="f20 bold">{{ title }}</p>
        </el-divider>
      </div>
      <div class="drawer-body">
        <slot class="slot-body" />
      </div>
      <div class="drawer-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 是否打开
    display: {
      type: Boolean
    },
    title: {
      type: String,
      default: '标题'
    },
    width: {
      type: String,
      default: '600px'
    },
    // 是否点击遮罩关闭
    maskClosable: {
      type: Boolean,
      default: true
    },
    // 是否显示遮罩
    mask: {
      type: Boolean,
      default: true
    },
    // 是否显示关闭按钮
    closable: {
      type: Boolean,
      default: true
    },
    // 是否在父级元素中打开
    inner: {
      type: Boolean,
      default: false
    },
    // 是否展示其他头部字段
    headerLable: {
      type: Boolean,
      default: false
    },
    label: {
      type: Object,
      default: () => {}
    },
    // 加载状态
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    maskClass() {
      return {
        'mask-show': this.mask && this.display,
        'mask-hide': !(this.mask && this.display),
        inner: this.inner
      }
    },
    mainClass() {
      return {
        'main-show': this.display,
        'main-hide': !this.display,
        inner: this.inner
      }
    },
    mainStyle() {
      return {
        width: this.width,
        right: this.display ? '0' : `-${this.width}`
      }
    }
  },
  mounted() {
    if (this.inner) {
      let box = this.$el.parentNode
      box.style.position = 'relative'
    }
  },
  methods: {
    closeMask() {
      // 这里update后面不能有空格 否则不生效 父组件必须添加 .sync修饰符
      this.maskClosable && this.$emit('update:display', false)
    },
    closeByButton() {
      // 这里update后面不能有空格 否则不生效 父组件必须添加 .sync修饰符
      this.$emit('update:display', false)
      this.$emit('beforeClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  .mask-show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba($color: #000, $alpha: 0.5);
    opacity: 1;
    transition: opacity 0.5s;
  }
  .mask-hide {
    opacity: 0;
    transition: opacity 0.5s;
  }
}

.main {
  position: fixed;
  z-index: 1000;
  top: 0px;
  height: 100%;
  background: #fff;
  transition: all 0.5s;
  border-left: 1px solid #eee;
}
.main-show {
  opacity: 1;
}
.main-hide {
  opacity: 0;
}
.inner {
  position: absolute;
}
.drawer-body {
  font-size: 14px;
  padding: 15px;
  height: calc(100% - 80px - 49px);
  overflow-y: auto;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 80px;
  width: 100%;
}
</style>
