/*
 * @Description:
 * @Date: 2023-02-28 14:17:16
 * @FilePath: \xinchang-carbon\src\store\modules\app.js
 */
import { getLoginUser, dictsAll } from '../../api/index'
const state = {
  navMenu: {
    // 左侧菜单栏状态
    collapse: localStorage.getItem('MENU_COLLAPSE') ? !!+localStorage.getItem('MENU_COLLAPSE') : false,
    collapseTransition: true
  },
  userInfo: localStorage.getItem('USER_INFO') ? localStorage.getItem('USER_INFO') : [], // 登录后的用户信息
  routes: [], // 通过鉴权后的路由
  dictsAll: [] // 字典项
}
function filterRoutes(routes) {
  const roles = state.userInfo.userResKeys
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    // if (route.meta && route.meta.auth && roles.some(role => route.meta.auth.includes(role))) {
    if (tmp.children) {
      tmp.children = filterRoutes(tmp.children, roles)
    }
    res.push(tmp)
    // }
  })
  return res
}
const mutations = {
  TOGGLE_MENU_COLLAPSE: state => {
    state.navMenu.collapse = !state.navMenu.collapse
    localStorage.setItem('MENU_COLLAPSE', state.navMenu.collapse ? 1 : 0)
  },
  SET_USER_INFO: (state, data) => {
    state.userInfo = data
    localStorage.setItem('USER_INFO', JSON.stringify(data))
  },
  SET_DICTS_ALL: (state, data) => {
    state.dictsAll = data
  },
  SET_ROUTES: (state, data) => {
    state.routes = data
  }
}
const actions = {
  toggleMenuCollapse({ commit }) {
    commit('TOGGLE_MENU_COLLAPSE')
  },
  userInfo({ commit }) {
    return new Promise(resolve => {
      getLoginUser().then(res => {
        const data = res.data
        commit('SET_USER_INFO', data)
        resolve(data)
      })
    })
  },
  dictsAll({ commit }) {
    return new Promise(resolve => {
      dictsAll().then(res => {
        commit('SET_DICTS_ALL', res.data)
        resolve()
      })
    })
  },
  authRouter({ commit }, router) {
    return new Promise(resolve => {
      const accessedRoutes = filterRoutes(router)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
